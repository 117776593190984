import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";

@component({
  name: "gistly-app-preview",
})
export class AppPreview extends LonaWebComponent {
  static $styles: HTMLTemplateElement[] = [
    css`
      :host {
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
      #root {
        height: 200px;
        width: 200px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <div id=root></div>
  `;
}
