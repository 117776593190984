import "@lona/dev-tools";
import "@lona/components/marketing/floating-navbar";
import "@lona/components/marketing/floating-navbar-item";
import "@lona/components/marketing/footer";
import "../lib/app-preview";

import { $ } from "@lona/dom-selectors";

addEventListener("focus", () => {
  $<HTMLVideoElement>("bg-video").play();
});
