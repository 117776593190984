export function getCookie(name: string): Option<string> {
  const nameq = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(nameq) == 0) {
      return c.substring(nameq.length, c.length);
    }
  }
  return null;
}

import "@lona/globals";
import { Logger } from "@lona/log";

Logger.install(false, getCookie("dev-mode") == "beta");

import "@lona/dev-mode";
import "@lona/dark-mode";
