export type DevMode = "beta" | "rc" | "prod";

import { Cookies } from "./cookies";
import { dev } from "./log";

export class DevModeUtils {
  static devModeIdentifier = "dev-mode";

  static get queryParam(): string {
    return `dev-mode=${DevModeUtils.getMode()}`;
  }

  static setMode(mode: DevMode, domain?: Option<string>) {
    Cookies.set(DevModeUtils.devModeIdentifier, {
      value: mode,
      domain,
    });
    DevModeUtils.emitUpdate();
    location.reload();
  }

  static getMode(): DevMode {
    return (Cookies.get(DevModeUtils.devModeIdentifier) as DevMode) ?? "prod";
  }

  static emitUpdate() {
    const mode = DevModeUtils.getMode();
    const meta =
      (document.getElementById("dev-mode") as HTMLMetaElement) ??
      document.createElement("meta");
    meta.id = "dev-mode";
    meta.name = "dev-mode";
    meta.content = mode;
    document.head.appendChild(meta);

    window.dispatchEvent(
      new CustomEvent("devModeChanged", {
        detail: {
          mode,
        },
      })
    );
  }
}

DevModeUtils.emitUpdate();

// @ts-ignore
window.addEventListener("setDevMode", (e: CustomEvent) => {
  dev(`[DevTools] setting mode to "${e.detail}"`);
  const mode: DevMode = e.detail;
  const rootDomain = window.location.host.split(".").slice(-2).join(".");
  DevModeUtils.setMode(mode, rootDomain);
});

window["devmode"] = DevModeUtils;
