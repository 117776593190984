import { css } from "../component-styles";

export namespace Typography {
  export type Tag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "caption";
  export type Size = Tag | "small";

  export function text(
    text: string,
    options?: Optional<{
      tag: Tag;
      size: Size;
    }>
  ): HTMLElement {
    const $text = document.createElement(
      options?.tag ?? tagFromSize(options?.size)
    );
    options?.size && $text.classList.add(options?.size);
    $text.textContent = text;
    return $text;
  }

  function tagFromSize(size?: Option<Size>): Tag {
    if (!size || size == "small") return "p";
    return size;
  }

  export const h1 = (t: string, size?: Size) => text(t, { tag: "h1", size });
  export const h2 = (t: string, size?: Size) => text(t, { tag: "h2", size });
  export const h3 = (t: string, size?: Size) => text(t, { tag: "h3", size });
  export const h4 = (t: string, size?: Size) => text(t, { tag: "h4", size });
  export const h5 = (t: string, size?: Size) => text(t, { tag: "h5", size });
  export const h6 = (t: string, size?: Size) => text(t, { tag: "h6", size });
  export const p = (t: string, size?: Size) => text(t, { tag: "p", size });
  export const caption = (t: string, size?: Size) =>
    text(t, { tag: "caption", size });
  export const small = (t: string, size?: Size) =>
    text(t, { size: size ?? "small" });

  /* prettier-ignore */
  export const style = /* css */`
    std-text, .text,
    *[placeholder]::before,
    button[text], .button[text],
    a, .a,
    .h1, .h2, .h3, .h4, .h5, .h6, .p, .ul, .ol, .caption, caption, .label,
    h1, h2, h3, h4, h5, h6, p, ul, ol, std-caption, .small, .text, label {
      --font-height: calc(1 - var(--cap-height) - var(--baseline));
      --rfont-size: calc(var(--font-size) / var(--font-height));
      --line-height-offset: calc((var(--line-height-ratio, 1.00) - 1) * var(--rfont-size) / 2);
      --rmargin-top:
        calc(
          -1 * var(--cap-height) * var(--rfont-size)
          - var(--line-height-offset)
          + var(--margin-top, 0px));
      --rmargin-bottom:
        calc(
          -1 * var(--baseline) * var(--rfont-size)
          - var(--line-height-offset)
          + var(--margin-bottom, 0px));

      font-weight: 400;
      font-size: var(--rfont-size);
      line-height: calc(var(--rfont-size) * var(--line-height-ratio, 1.00));
      margin: 0;
      padding: 0;
      margin-top: var(--rmargin-top);
      margin-bottom: var(--rmargin-bottom);
      min-height: var(--font-size);
    }

    .headline { --font-size: var(--headline-size) }
    .h1, h1 { --font-size: var(--h1-size); --line-height-ratio: 1.2; }
    .h2, h2 { --font-size: var(--h2-size); --line-height-ratio: 1.2; }
    .h3, h3 { --font-size: var(--h3-size); --line-height-ratio: 1.2; }
    .h4, h4 { --font-size: var(--h4-size); --line-height-ratio: 1.2; }
    .h5, h5 { --font-size: var(--h5-size); --line-height-ratio: 1.2; }
    .h6, h6 { --font-size: var(--h6-size); --line-height-ratio: 1.2; }
    a, .a, .p, .ul, .ol, p, ul, ol { --font-size: var(--p-size); --line-height-ratio: 1.1; }
    *[placeholder]::before { --font-size: var(--placeholder-size, var(--p-size)); }
    label, .label,
    .caption, std-caption { --font-size: var(--caption-size); --line-height-ratio: 1.1; }
    .small { --font-size: var(--small-size); --line-height-ratio: 1.1; }

    input::placeholder {
      color: var(--tertiary-text-color);
    }
  `;

  export const $style = css(style);

  export const $maxLines = css`
    .max-lines {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
    .max-lines-1 {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
    }
    .max-lines-2 {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
    .max-lines-3 {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;
    }
  `;
  export const $noWrap = css`
    .no-wrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;
}
