export type LogCategory = "network" | "ui" | "unknown" | "init" | "nav" | "sw";
export type LogFunction = (...data: any[]) => void;
export type LogFunctionWithCategory = (c?: Option<LogCategory>) => LogFunction;

export namespace LoggerUtils {
  let logLevel = 0;

  const startMs = new Date().getTime();
  let lastLogMs = startMs;

  const categoryToBgColor: { [k in LogCategory]: string } = {
    network: "#cbe9c8",
    init: "#999cff",
    ui: "#999cff",
    nav: "#dbacff",
    unknown: "#d1d1d1",
    sw: "#fe887c",
  };

  const layout = "padding-left:4px;padding-right:4px;border-radius:4px";
  export const prefix = (
    category: Option<LogCategory>,
    id: string,
    fgColor: string,
    bgColor: string,
    marginLeft: number = -logLevel * 14
  ) => [
    `%c${id}%c${String(category ?? "unknown").padStart(
      8,
      " "
    )}%c${timestamp()}%c    ›`,
    `color:${fgColor};background-color:${bgColor};${layout};margin-left:${marginLeft}px;margin-right:4px;`,
    `color:black;background-color:${
      categoryToBgColor[category ?? "unknown"]
    };${layout};margin-right:4px;`,
    "",
    `margin-right: 10px;`,
  ];

  function msToTimeString(ms: number) {
    if (ms > 30_000) {
      return `${Math.trunc(ms / 1000)}.${Math.trunc(
        (ms % 1000) / 100
      )}s`.padStart(10, " ");
    } else if (ms > 1_000) {
      return `${Math.trunc(ms / 1000)}.${Math.trunc(
        (ms % 1000) / 10
      )}s`.padStart(10, " ");
    } else {
      return `${ms}ms`.padStart(10, " ");
    }
  }

  export function timestamp(): string {
    const prevLastLogMs = lastLogMs;
    lastLogMs = new Date().getTime();
    const deltaMs = lastLogMs - prevLastLogMs;
    let sb = msToTimeString(lastLogMs - startMs);
    sb += deltaMs > 0 ? msToTimeString(deltaMs) : "          ";
    return sb;
  }

  export function getStartMs(): number {
    return startMs;
  }

  export function trace(
    _target: Object,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<any>
  ): TypedPropertyDescriptor<any> {
    const method = descriptor.value!;
    descriptor.value = function (...args: any[]) {
      pushLogLevel();
      console.group(
        `%c ${String(this.constructor.name)}.${String(propertyKey)}`,
        `font-weight:400;position:absolute;display:block;color:gray;`
        // `margin-left:260px;font-weight:400;position:absolute;display:block;color:gray;`,
      );
      method.apply(this, args);
      console.groupEnd();
      popLogLevel();
    };
    return descriptor;
  }

  export function pushLogLevel() {
    logLevel += 1;
  }
  export function popLogLevel() {
    logLevel -= 1;
  }
}

const _info: LogFunctionWithCategory = (c) =>
  console.log.bind(null, ...LoggerUtils.prefix(c, "i", "black", "#999cfe"));
const _warn: LogFunctionWithCategory = (c) =>
  console.log.bind(null, ...LoggerUtils.prefix(c, "w", "black", "#fbd55a"));
const _err: LogFunctionWithCategory = (c) =>
  console.log.bind(null, ...LoggerUtils.prefix(c, "e", "black", "#fe887c"));
const _dbg: LogFunction = console.log.bind(
  null,
  ...LoggerUtils.prefix("ui", "~", "white", "black")
);

export class Logger {
  static noop: LogFunctionWithCategory = () => () => {};

  static info: LogFunctionWithCategory = Logger.noop;
  static warn: LogFunctionWithCategory = Logger.noop;
  static err: LogFunctionWithCategory = Logger.noop;
  static dbg: LogFunction = () => {};

  static install(isServiceAccount: boolean, isBeta: boolean) {
    if (!isServiceAccount && !isBeta) return;
    console.log("Installing logger");
    Logger.info = _info;
    Logger.warn = _warn;
    Logger.err = _err;
    Logger.dbg = _dbg;
    window["dbg"] = Logger.dbg;
  }
}

export const dev: LogFunction = () => {};
// export const dev: LogFunction = _dbg;
export const sw = Logger.info("sw");
