import {
  FixedOffset,
  DateTime,
  Result,
  DateRegion,
  NaiveDate,
  Utc,
  Tzabbr,
  FixedTimezone,
  NaiveDateTime,
  DateTimeRegion,
  TimezoneInfo,
  Tzname,
  Epoch,
} from "./mod";
import { MsSinceEpoch } from "./units/units";

export class TimezoneRegion {
  readonly fullname: string;
  readonly transitions: TimezoneRegion.Transition[];
  private tzs: Map<Tzabbr, FixedTimezone> = new Map();

  constructor(fullname: string, transitions: TimezoneRegion.Transition[]) {
    this.fullname = fullname;
    this.transitions = transitions.sort((a, b) => a.before.mse - b.before.mse);
  }

  activeTransition(mse: MsSinceEpoch): Option<TimezoneRegion.Transition> {
    const found = this.bst(mse);
    if (found < 0) return null;

    const transition = this.transitions[found];
    return transition;
  }

  transitionsBetween({
    start,
    end,
  }: {
    start: MsSinceEpoch;
    end: MsSinceEpoch;
  }): TimezoneRegion.Transition[] {
    const startIdx = this.bst(start);
    const endIdx = this.bst(end);
    return this.transitions.slice(
      startIdx > 0 ? startIdx : undefined,
      endIdx > 0 ? endIdx + 1 : undefined
    );
  }

  private bst(target: MsSinceEpoch): number {
    let left = 0;
    let right = this.transitions.length - 1;
    let result = -1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const cmp = this.transitions[mid].before.mse - target;
      if (cmp == 0) {
        return mid;
      } else if (cmp > 0) {
        right = mid - 1;
      } else {
        result = mid;
        left = mid + 1;
      }
    }

    return result;
  }

  today(): DateRegion {
    return new DateRegion(NaiveDate.today(), this);
  }

  now(): DateTimeRegion {
    return this.datetime(NaiveDateTime.fromMse(Epoch.currentMse()));
  }

  date(nd: NaiveDate): DateRegion {
    return new DateRegion(nd, this);
  }

  datetime(ndt: NaiveDateTime): DateTimeRegion {
    return new DateTimeRegion(ndt, this);
  }

  datetimeResolved(ndt: NaiveDateTime): DateTime<FixedOffset> {
    const transition = this.activeTransition(ndt.date.mse);
    if (!transition) return ndt.withTz(Utc);

    const tzabbr = transition.after.tzabbr;
    let tz = this.tzs.get(tzabbr);
    if (tz != null) return ndt.withTz(tz);

    tz = new FixedTimezone(tzabbr, {
      offset: transition.after.time.tz.info.offset,
      tzabbr,
      tzname: this.fullname,
    });
    this.tzs.set(tzabbr, tz);
    return ndt.withTz(tz);
  }

  print() {
    for (const [idx, transition] of this.transitions.entries()) {
      TimezoneRegion.Transition.print(transition, idx);
    }
  }
}

export namespace TimezoneRegion {
  /**
   * Loader
   */
  export interface Loader {
    load: (tzname: Tzname) => Promise<Transition[]>;
  }

  export const DYNAMIC_LOADER: Loader = {
    load: async (tzname: Tzname) => {
      const resp = await fetch(
        `https://static.lona.so/timezones/2024b/1900_2050/${tzname.replaceAll(
          "/",
          "~"
        )}.json`
      );
      const json = await resp.json();
      return json.map(Transition.parse);
    },
  };
  let loader: Loader = DYNAMIC_LOADER;

  /**
   * Api
   */
  const defaultCache = new Map([["UTC", new TimezoneRegion("UTC", [])]]);
  export async function get(
    tzname: string = TimezoneInfo.local().tzname!
  ): Promise<TimezoneRegion> {
    const existing = defaultCache.get(tzname);
    if (existing != null) return existing;

    const transitions = await loader.load(tzname);
    const region = new TimezoneRegion(tzname, transitions);
    defaultCache.set(tzname, region);

    return region;
  }

  export function setLoader(l: Loader) {
    loader = l;
  }

  /**
   * Transitions
   */
  export interface Transition {
    before: {
      mse: MsSinceEpoch;
      time: DateTime<FixedOffset>;
      tzabbr: string;
    };
    after: {
      time: DateTime<FixedOffset>;
      tzabbr: string;
    };
  }

  export namespace Transition {
    export type Serialized = {
      before: string;
      before_tz_abbr: string;
      after: string;
      after_tz_abbr: string;
    };

    export function parse(
      t: Transition.Serialized,
      tzname: Tzname
    ): Transition {
      const before = Result.unwrap(
        DateTime.fromRfc3339(t.before, tzname, t.before_tz_abbr)
      );
      const after = Result.unwrap(
        DateTime.fromRfc3339(t.after, tzname, t.after_tz_abbr)
      );
      return {
        before: {
          mse: before.mse,
          time: before,
          tzabbr: t.before_tz_abbr,
        },
        after: {
          time: after,
          tzabbr: t.after_tz_abbr,
        },
      };
    }

    export function print(transition: Transition, idx?: Option<number>) {
      console.log(idx, toString(transition));
    }

    export function toString(transition: Transition): string {
      return `${
        transition.before.mse
      } ${transition.before.time.rfc3339()} ${transition.after.time.rfc3339()}`;
    }
  }
}
