import { MathUtils } from "@lona/math";

import { GenericRange } from "../range";
import { Result } from "../result";

import { Hms, HmsLike } from "./hour-mins-secs";
import { Time } from "../time";
import { Day, Ms } from "./units";

export interface TimeUnitLike extends HmsLike {
  readonly days: number;
  readonly ms: number;
}
export type TimeUnitLikeOpt = Optional<TimeUnitLike>;

export class TimeUnit implements TimeUnitLike {
  readonly sign: 1 | -1;

  constructor(asMs: number) {
    // NOTE:
    // never allow sub-ms precision (most times it's floating point precision)
    // errors;
    const ms = Math.round(asMs);
    this.sign = ms > 0 ? 1 : -1;
    this.asMs = ms;
  }

  /**
   * @param ms
   *    Milliseconds from [-Inf..Inf]
   *    -5s represents 5s before midnight, ie. 23:59:55
   */
  static divByDay(ms: number): { div: Day; rem: Ms } {
    const rem = MathUtils.mod(ms, Time.MS_PER_DAY) as Ms;
    return {
      div: Math.floor(ms / Time.MS_PER_DAY) as Day,
      rem,
    };
  }

  static days(daysF: number): TimeUnit {
    return new TimeUnit(daysF * Time.MS_PER_DAY);
  }

  static hours(hrsF: number): TimeUnit {
    return new TimeUnit(hrsF * Time.MS_PER_HR);
  }

  static fromHms(hms: Optional<HmsLike>, sign: number = 1): TimeUnit {
    return new TimeUnit(Hms.toMs(hms) * sign);
  }

  static fromHmsStr(
    hours: Option<string> = null,
    minutes: Option<string> = null,
    seconds: Option<string> = null,
    sign: number = 1
  ): Result<TimeUnit> {
    const hrs = hours ? parseInt(hours) : 0;
    if (isNaN(hrs)) return Error(`parse/hrs: ${hours}`);

    const mins = minutes ? parseInt(minutes) : 0;
    if (isNaN(mins)) return Error(`parse/mins: ${minutes}`);

    const secs = seconds ? parseInt(seconds) : 0;
    if (isNaN(secs)) return Error(`parse/secs: ${seconds}`);

    const hms = {
      hrs,
      mins,
      secs,
    };
    if (!Hms.isStrict(hms))
      return Error(`parse/strict: ${JSON.stringify(hms)}`);

    return TimeUnit.fromHms(hms, sign);
  }

  /**
   * @param s ie. 8:30, 8, 14:20
   */

  static todoParseToHrs(s: string) {
    const [hr, mins] = s.split(":");
    return parseInt(hr) + (mins ? parseInt(mins) / 60 : 0);
  }

  /**
   * Time relative to their larger time unit.
   *
   * ie.
   *   Let {NoUnit} represent the largest time unit (defined as decade, century, etc)
   *   possible.
   *
   *
   *   days -> daysOf{NoUnit}
   *   hrs -> hrOfDay
   *   mins -> minOfDay
   *   secs -> secOfMin
   *   ms -> msOfSec
   *
   * todo: floating point precision error. is adding eps appropriate?
   */
  get days(): number {
    return Math.floor(this.daysF + 0.01);
  }
  get hrs(): number {
    return Math.floor(this.hrsF + 0.01);
  }
  get mins(): number {
    return Math.floor(this.minsF + 0.01);
  }
  get secs(): number {
    return Math.floor(this.secsF + 0.01);
  }
  get ms(): number {
    return this.asMs % Time.MS_PER_DAY_INV;
  }

  get daysF(): number {
    return this.asMs / Time.MS_PER_DAY;
  }
  get hrsF(): number {
    return (this.asMs % Time.MS_PER_DAY) * Time.MS_PER_HR_INV;
  }
  get minsF(): number {
    return (this.asMs % Time.MS_PER_HR) * Time.MS_PER_MIN_INV;
  }
  get secsF(): number {
    return (this.asMs % Time.MS_PER_MIN) * Time.MS_PER_SEC_INV;
  }

  get absAsMs(): number {
    return this.sign * this.asMs;
  }

  /**
   * UNSIGNED
   *
   * as{Ms|Days|Mins|Seconds}
   *
   * An absolute unit of time relative to nothing else.
   *
   * ie.
   *   Let {NoUnit} represent the largest time unit (defined as decade, century, etc)
   *   possible.
   *
   *   days -> daysOf{NoUnit}
   *   hrs -> hrOf{NoUnit}
   *   mins -> minOf{NoUnit}
   *   secs -> secOf{NoUnit}
   *   ms -> msOf{NoUnit}
   */
  readonly asMs: number;

  get asDays(): number {
    return Math.floor(this.asDaysF);
  }
  get asHrs(): number {
    return Math.floor(this.asHrsF);
  }
  get asMins(): number {
    return Math.floor(this.asMinsF);
  }
  get asSecs(): number {
    return Math.floor(this.asSecsF);
  }

  get asDaysF(): number {
    return this.asMs * Time.MS_PER_DAY_INV;
  }
  get asHrsF(): number {
    return this.asMs * Time.MS_PER_HR_INV;
  }
  get asMinsF(): number {
    return this.asMs * Time.MS_PER_MIN_INV;
  }
  get asSecsF(): number {
    return this.asMs * Time.MS_PER_SEC_INV;
  }

  /*
   * Methods
   */
  get meridiem(): "am" | "pm" {
    return this.asMs >= Time.MS_PER_DAY / 2 ? "pm" : "am";
  }

  add(delta: TimeUnitLikeOpt): TimeUnit {
    return new TimeUnit(this.asMs + TimeUnit.from(delta).asMs);
  }

  sub(delta: TimeUnitLikeOpt): TimeUnit {
    return new TimeUnit(this.asMs - TimeUnit.from(delta).asMs);
  }

  mult(scalar: number): TimeUnit {
    return new TimeUnit(this.asMs * scalar);
  }

  nearest(
    nearest: TimeUnitLikeOpt,
    op: (n: number) => number = Math.round
  ): TimeUnit {
    const nearestTu = TimeUnit.from(nearest);
    const multiple = op(this.asMs / nearestTu.asMs);
    return new TimeUnit(multiple * nearestTu.asMs * this.sign);
  }

  round(nearest: TimeUnitLikeOpt): TimeUnit {
    return this.nearest(nearest);
  }

  floor(floor: TimeUnitLikeOpt): TimeUnit {
    return this.nearest(floor, Math.floor);
  }

  ceil(ceil: TimeUnitLikeOpt): TimeUnit {
    return this.nearest(ceil, Math.ceil);
  }

  rfc3339(): string {
    // todo: assert sign is positive
    return [
      Math.abs(this.hrs).toString().padStart(2, "0"),
      Math.abs(this.mins).toString().padStart(2, "0"),
      Math.abs(this.secs).toString().padStart(2, "0"),
    ].join(":");
  }

  asSignedHm(): string {
    return [
      this.sign > 0 ? "+" : "-",
      Math.abs(this.hrs).toString().padStart(2, "0"),
      ":",
      Math.abs(this.mins).toString().padStart(2, "0"),
    ].join("");
  }

  toString(): string {
    return this.rfc3339();
  }

  /* Static */

  static ZERO = new TimeUnit(0);
  static HOUR24 = new TimeUnit(24 * Time.MS_PER_HR);
  static MIN15 = new TimeUnit(15 * Time.MS_PER_MIN);
  static MIN5 = new TimeUnit(5 * Time.MS_PER_MIN);
  static DAY1 = TimeUnit.days(1);

  static asMs(delta: TimeUnitLikeOpt): number {
    if (delta instanceof TimeUnit) return delta.asMs;
    return (
      (delta.days ? delta.days * Time.MS_PER_DAY : 0) +
      (delta.hrs ? delta.hrs * Time.MS_PER_HR : 0) +
      (delta.mins ? delta.mins * Time.MS_PER_MIN : 0) +
      (delta.secs ? delta.secs * Time.MS_PER_SEC : 0) +
      (delta.ms ?? 0)
    );
  }

  static fromMs(ms: Ms): TimeUnit {
    return new TimeUnit(ms);
  }

  static from(delta: TimeUnitLikeOpt): TimeUnit {
    if (delta instanceof TimeUnit) return delta;
    return new TimeUnit(TimeUnit.asMs(delta));
  }

  static msToString(
    ms: number,
    formatter: TimeDeltaFormatter = DEFAULT_FORMATTER
  ): string {
    return formatter.format(
      TimeUnit.from({
        ms,
      })
    );
  }
}

export namespace TimeUnit {
  export class Range extends GenericRange<TimeUnit> {
    get length(): TimeUnit {
      return this.end.sub(this.start);
    }
  }

  export namespace Formatter {
    export function createReadableHourString(
      hour: number,
      min: number,
      options?: {
        includeAmPm?: boolean;
        allCaps?: boolean;
        includeMinutes?: boolean;
        use24Hours?: boolean;
      }
    ): string {
      const { allCaps, includeAmPm, includeMinutes, use24Hours } = {
        allCaps: options?.allCaps ?? true,
        includeAmPm: options?.includeAmPm ?? true,
        includeMinutes: options?.includeMinutes ?? true,
        use24Hours: options?.use24Hours ?? false,
      };
      const hoursMod = use24Hours ? 24 : 12;

      let amPm = hour >= 12 ? "pm" : "am";
      if (allCaps) {
        amPm = amPm.toUpperCase();
      }
      let displayHour = hour % hoursMod;
      displayHour = displayHour == 0 ? hoursMod : displayHour;
      min = Math.round(min);
      if (min == 0 && !includeMinutes) {
        return includeAmPm ? `${displayHour} ${amPm}` : String(displayHour);
      } else {
        const timeStr = `${displayHour}:${min.toString().padStart(2, "0")}`;
        return includeAmPm ? `${timeStr} ${amPm}` : timeStr;
      }
    }

    export function render(
      time: TimeUnit,
      options?: {
        includeAmPm?: boolean;
        allCaps?: boolean;
        includeMinutes?: boolean;
      }
    ): string {
      return Formatter.createReadableHourString(time.hrs, time.mins, options);
    }
  }
}

export interface TimeDeltaFormatter {
  format(delta: TimeUnit): string;
}

export class EnTimeDeltaFormatter implements TimeDeltaFormatter {
  static pluralize(s: string, n: number) {
    if (n > 1) return s + "s";
    return s;
  }

  format(delta: TimeUnit): string {
    const pluralize = EnTimeDeltaFormatter.pluralize;
    const sb: string[] = [];
    if (delta.days > 0) {
      sb.push(`${delta.days} ${pluralize("day", delta.days)}`);
    }
    if (delta.hrs > 0) {
      sb.push(`${delta.hrs} ${pluralize("hour", delta.hrs)}`);
    }
    if (delta.mins > 0) {
      sb.push(`${delta.hrs} ${pluralize("min", delta.mins)}`);
    }
    if (delta.secs > 0) {
      sb.push(`${delta.secs} ${pluralize("sec", delta.secs)}`);
    }
    return sb.join(", ");
  }
}

export const DEFAULT_FORMATTER = new EnTimeDeltaFormatter();
