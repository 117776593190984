import {
  FixedOffset,
  DateTime,
  MsSinceEpoch,
  NaiveDateTime,
  TimeUnit,
} from "./mod";
import { NaiveDate } from "./naive-date";
import { Time } from "./time";
import { TimezoneRegion } from "./timezone-region";

export interface TimeRange {
  start: MsSinceEpoch;
  end: MsSinceEpoch;
}

export interface DateRange {
  start: DateTime<FixedOffset>;
  end: DateTime<FixedOffset>;
}

export class DateRegion {
  readonly nd: NaiveDate;
  readonly tz: TimezoneRegion;

  readonly cache: Optional<{
    resolved: DateTime.Range<FixedOffset>[],
  }> = {};

  constructor(nd: NaiveDate, tz: TimezoneRegion) {
    this.nd = nd;
    this.tz = tz;
  }

  get timeRange(): TimeRange {
    const start = this.nd.mse;
    return {
      start,
      end: (start + Time.MS_PER_DAY) as MsSinceEpoch,
    };
  }

  resolve(): DateTime.Range<FixedOffset>[] {
    if (this.cache.resolved) return this.cache.resolved;
    return (this.cache.resolved = this.#resolve());
  }

  /**
   * Given a naive date (2024-11-03), produces the following:
   *
   *   2024-11-03T00:00:00-07:00 2024-11-03T02:00:00-07:00
   *   2024-11-03T01:00:00-08:00 2024-11-04T00:00:00-08:00
   *
   *   (Simplified as...)
   *
   *   00:00 (-7 off) to 02:00 (-7 off)
   *   01:00 (-8 off) to 23:59 (-8 off)
   *
   *   These represent all datetime ranges representing a given logical or naive
   *   date. In simple english, 11-03 has 2 datetime ranges representing it when
   *   considering America/Los_Angles (represented above).
   *
   *   11-04, for instance, only has 1. And is represented by
   *     00:00 to 23:59 (-8 off).
   *
   */
  #resolve(): DateTime.Range<FixedOffset>[] {
    // console.log("TRANSITIONS for ", this.nd.toString());
    // console.log("vvvvvvvvvvv");
    const transitions = this.tz.transitionsBetween({
      start: this.nd.mse - Time.MS_PER_DAY as MsSinceEpoch,
      end: this.nd.mse + Time.MS_PER_DAY as MsSinceEpoch,
    }).filter((t) => {
      // console.log(t.before.time.rfc3339());
      // console.log(t.after.time.rfc3339());
      return t.after.time.ndt.date.equals(this.nd);
    });

    // console.log("^^^^^^^^^^");
    // console.log("FOUND");
    // for (const t of transitions) {
    //   console.log(t.before.time.toString());
    //   console.log(t.after.time.toString());
    // }
    // console.log("^^^^^^^^^^");

    const dateStart = this.dateStart;
    if (transitions.length == 0) return [
      new DateTime.Range(dateStart, dateStart.add({hrs: 24}))
    ];

    const transition = transitions[0];
    return [
      new DateTime.Range(dateStart, transition.after.time.toTz(dateStart.tz)),
      new DateTime.Range(transition.after.time, transition.after.time.toEndOfDay()),
    ];
  }

  get dateStart(): DateTime<FixedOffset> {
    return this.tz.datetimeResolved(
      new NaiveDateTime(NaiveDate.fromMse(this.timeRange.start))
    );
  }

  get dateEnd(): DateTime<FixedOffset> {
    return this.tz.datetimeResolved(
      new NaiveDateTime(NaiveDate.fromMse(this.timeRange.end))
    );
  }

  get dateRange(): DateTime.Range<FixedOffset> {
    return new DateTime.Range(this.dateStart, this.dateEnd);
  }

  get dayLength(): TimeUnit {
    return this.dateEnd.durationSince(this.dateStart);
  }

  toString(): string {
    return `${this.nd.rfc3339()} ${this.tz.fullname}`;
  }

  transitions(): TimezoneRegion.Transition[] {
    return this.tz.transitionsBetween(this.timeRange);
  }
}

export class DateTimeRegion {
  readonly ndt: NaiveDateTime;
  readonly tz: TimezoneRegion;

  constructor(ndt: NaiveDateTime, tz: TimezoneRegion) {
    this.ndt = ndt;
    this.tz = tz;
  }
}
