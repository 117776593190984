import { NaiveDate } from "../naive-date";
import { Result } from "../result";
import { DateUnit, DateUnitType } from "./date-unit";
import { DayOfMonth1, Month0, Month1, WeekOfYear1 } from "./units";
import { Week } from "./week";
import { YearMonth } from "./year-month";
import { YearMonthDay, Ymd1Like } from "./year-month-day";

export type PartialDateInlined = [YearMonthDay.MaybeValid, DateUnitType];

export class PartialDate {
  constructor(
    readonly type: DateUnitType,
    readonly yr: number,
    readonly mth: Option<Month1> = null,
    readonly day: Option<DayOfMonth1> = null,
    readonly week: Option<WeekOfYear1> = null,
    readonly cached: Optional<{
      start: NaiveDate;
      end: NaiveDate;
    }> = {}
  ) {}

  get index(): number {
    switch (this.type) {
      case "years":
        return this.yr;
      case "months":
        return this.yr * 12 + this.mth! - 1;
      case "days":
        return this.start.dse;
      case "weeks":
        return Week.weeksSinceEpoch(this.yr, this.week!);
    }
  }

  toString(): string {
    switch (this.type) {
      case "years":
        return `y-${this.yr}`;
      case "months":
        return `ym-${this.yr}-${this.mth!}`;
      case "days":
        return `ymd-${this.yr}-${this.mth!}-${this.day!}`;
      case "weeks":
        return `yw-${this.yr}-${this.week!}`;
    }
  }

  private _start(): NaiveDate {
    switch (this.type) {
      case "years":
        return NaiveDate.fromYmd1Exp(this.yr, 1, 1);
      case "months":
        return NaiveDate.fromYmd1Exp(this.yr, this.mth!, 1);
      case "days":
        return NaiveDate.fromYmd1Exp(this.yr, this.mth!, this.day!);
      case "weeks":
        return Week.dateFromWeekno(this.yr, this.week!);
    }
  }

  get start(): NaiveDate {
    return this.cached.start ?? (this.cached.start = this._start());
  }

  get end(): NaiveDate {
    return this.cached.end ?? (this.cached.end = this.start.add(this.du));
  }

  equals(other: PartialDate): boolean {
    return (
      this.type == other.type &&
      this.yr == other.yr &&
      this.mth == other.mth &&
      this.day == other.day &&
      this.week == other.week
    );
  }

  get du(): DateUnit {
    return new DateUnit(1, this.type);
  }

  toRange(): NaiveDate.Range {
    return new NaiveDate.Range(this.start, this.end);
  }

  add(scalar: number): PartialDate {
    switch (this.type) {
      case "years": {
        return new PartialDate(this.type, this.yr + scalar);
      }
      case "months": {
        const { yr, mth } = YearMonth.add(
          {
            yr: this.yr,
            mth: this.mth as Month1,
          },
          {
            mth: scalar as Option<Month0>,
          }
        );
        return new PartialDate(this.type, yr, mth);
      }
      case "days": {
        return PartialDate.fromDate(this.start.addDays(scalar));
      }
      case "weeks": {
        const { yr, week } = Week.addWeeksToYearWeek(
          { yr: this.yr, week: this.week! },
          scalar
        );
        return new PartialDate(this.type, yr, null, null, week);
      }
    }
  }

  offsetFrom(date: NaiveDate): {
    index: number;
    days: number;
  } {
    switch (this.type) {
      case "years": {
        return {
          index: date.yr - this.yr,
          days: date.dayOfYear,
        };
      }
      case "months": {
        return {
          index: (date.yr - this.yr) * 12 + (date.yr - this.mth!),
          days: date.dayOfMonth,
        };
      }
      case "days": {
        return {
          index: this.start.differenceInDays(date),
          days: 0,
        };
      }
      case "weeks": {
        return {
          index: Week.differenceInWeeks(
            { yr: this.yr, week: this.week! },
            { yr: date.yr, week: date.wno }
          ),
          // days: date.dayOfWeek.dow - 1,
          days: date.dayOfWeek.isoDow,
        };
      }
    }
  }
}

export namespace PartialDate {
  export function parse(s: string): Result<PartialDate> {
    const [ty, yr, mw, d] = s.split("-");
    switch (ty) {
      case "ymd":
        return PartialDate.fromStr("days", yr, mw, d, null);
      case "ym":
        return PartialDate.fromStr("months", yr, mw, null, null);
      case "y":
        return PartialDate.fromStr("years", yr, null, null, null);
      case "yw":
        return PartialDate.fromStr("weeks", yr, null, null, mw);
    }
    return Error("unknown ty");
  }

  export function fromDate(d: NaiveDate): PartialDate {
    return new PartialDate("days", d.yr, d.month1, d.dayOfMonth, null, {
      start: d,
    });
  }

  export function fromStr(
    type: DateUnitType,
    yr: string,
    mth1: Option<string>,
    dom1: Option<string>,
    wk1: Option<string>
  ): Result<PartialDate> {
    let _yr = parse1idx(yr);
    if (_yr instanceof Error) return _yr;

    let _mth1 = parse1idx(mth1);
    if (_mth1 instanceof Error) return _mth1;

    let _dom1 = parse1idx(dom1);
    if (_dom1 instanceof Error) return _dom1;

    let _wk1 = parse1idx(wk1);
    if (_wk1 instanceof Error) return _wk1;

    return new PartialDate(
      type,
      _yr!,
      _mth1 as Month1,
      _dom1 as DayOfMonth1,
      _wk1 as WeekOfYear1
    );
  }

  function parse1idx(s: Option<string>): Result<Option<number>> {
    if (s == null) return null;
    const num = parseInt(s);
    if (isNaN(num)) return Error("not a number");
    if (num < 1) return Error("<1");
    return num;
  }
}
