import { Style } from "./component-styles";
import { Flexbox } from "./components/flexbox";
import { CSSStyleWithVariables, DomUtils } from "./dom";
import { $, $_maybe, $qs, $qs_maybe, $qsa } from "./dom-selectors";
import { $$ } from "./fastdom";
import { GESTURE_MANAGER, GestureManager } from "./gesture-manager";
import { Typography } from "./ui/typography";

export const template = (
  s: TemplateStringsArray | string
): HTMLTemplateElement => {
  const $template = document.createElement("template");
  $template.innerHTML = s.toString();
  return $template;
};

// https://stackoverflow.com/questions/34098023/typescript-self-referencing-return-type-for-static-methods-in-inheriting-classe
export interface LonaWebComponentClass<T extends LonaWebComponent = any> {
  componentName: string;
  new (): T;

  $baseStyles: Style.Compat[];
  $styles: Style.Compat[];
  $icons: Option<string[]>;
  $iconsTemplate: Option<HTMLTemplateElement>;
  $html: Option<HTMLTemplateElement>;

  pool: Option<T[]>;
  make: <Subclass extends typeof LonaWebComponent>() => InstanceType<Subclass>;
  recycle: (instance: T) => void;
  _recycle: (instance: T) => void;

  // todo: lifecycles?
  // onRecycle: (instance: T) => void;
}

export class LonaWebComponent extends HTMLElement {
  static componentName: string = "div";

  static $baseStyles: Style.Compat[] = [
    Style.$borderBoxStyle,
    Flexbox.$style,
    Typography.$style,
  ];
  static $styles: Style.Compat[] = [];
  static $html: Option<HTMLTemplateElement>;
  static $icons: Option<string[]>;
  static $iconsTemplate: Option<HTMLTemplateElement>;
  static pool: Option<any[]>; // NOTE: the pool can't be initialized here otherwise everyone will share the same pool!!
  private pipStyleSheets: Option<HTMLStyleElement[]>;

  constructor() {
    super();
    // @ts-ignore
    const constructor: LonaWebComponentClass = this.constructor;
    // @ts-ignore
    constructor.inflate(constructor, this);
  }

  static make<Subclass extends typeof LonaWebComponent>(
    this: Subclass
  ): InstanceType<Subclass> {
    const reused = this.pool?.pop();
    // info("ui")(`[${this.name}] pool:`, reused != null, this.componentName);
    return (reused ??
      document.createElement(this.componentName)) as InstanceType<Subclass>;
  }

  static recycle<T extends LonaWebComponent>(
    this: LonaWebComponentClass<T>,
    instance: T
  ) {
    performance.mark(`[lona-web-component] recycle start`);
    instance.parentElement?.removeChild(instance);
    performance.mark(`[lona-web-component] recycle end`);
    performance.measure(
      `[lona-web-component] recycle`,
      `[lona-web-component] recycle start`,
      `[lona-web-component] recycle end`
    );
    // 1) Wait until the current layout pass is over
    // setTimeout(() => {
    //   if (typeof requestIdleCallback != "undefined") {
    //     requestIdleCallback(() => this._recycle(instance));
    //   } else {
    //     this._recycle(instance);
    //   }
    // });
    $$.mutate(() => this._recycle(instance));
  }

  static _recycle<T extends LonaWebComponent>(instance: T) {
    instance.style.cssText = "";
    instance.innerHTML = "";
    while (instance.attributes.length > 0) {
      instance.removeAttribute(instance.attributes[0].name);
    }
    if (!this.pool) {
      this.pool = [instance];
    } else {
      this.pool.push(instance);
    }
  }

  onRecycle() {}

  recycle() {
    // @ts-ignore
    const constructor: LonaWebComponentClass = this.constructor;
    constructor.recycle(this);
  }

  adoptedCallback() {
    const w = this.ownerDocument.defaultView;
    if (!w) return;
    if (!w["is-pip"]) return;
    if (!Style.canAdoptStylesheet()) return;
    if (this.pipStyleSheets == null) {
      // @ts-ignore
      const constructor: LonaWebComponentClass = this.constructor;
      this.pipStyleSheets = [
        ...constructor.$baseStyles,
        ...constructor.$styles,
      ].map(Style.makeWith);
    }

    for (const $sheet of this.pipStyleSheets) {
      this.shadowRoot!.appendChild($sheet);
    }
  }

  private static inflate<T extends LonaWebComponent>(
    clazz: LonaWebComponentClass<T>,
    instance: T
  ) {
    const shadowRoot = instance.attachShadow({ mode: "open" });

    for (const style of [...clazz.$baseStyles, ...clazz.$styles]) {
      instance.adoptStyle(style);
    }

    if (clazz.$icons) {
      if (clazz.$iconsTemplate == null) {
        const icons = template(DomUtils.importSvgs(clazz.$icons));
        clazz.$iconsTemplate = icons;
        shadowRoot.appendChild(icons.content.cloneNode(true));
      } else {
        shadowRoot.appendChild(clazz.$iconsTemplate.content.cloneNode(true));
      }
    }

    clazz.$html && shadowRoot.appendChild(clazz.$html.content.cloneNode(true));
  }

  adoptStyle(style: Style.Compat) {
    if (style instanceof CSSStyleSheet) {
      this.shadowRoot!.adoptedStyleSheets.push(style);
    } else {
      this.shadowRoot!.appendChild(style.cloneNode(true));
    }
  }

  $<T extends HTMLElement = HTMLElement>(s: string): T {
    return $<T>(s, this.shadowRoot!);
  }
  $_maybe<T extends HTMLElement = HTMLElement>(s: string): Option<T> {
    return $_maybe<T>(s, this.shadowRoot!);
  }
  $qs<T extends HTMLElement = HTMLElement>(s: string): T {
    return $qs<T>(s, this.shadowRoot!);
  }
  $qs_maybe<T extends HTMLElement = HTMLElement>(s: string): Option<T> {
    return $qs_maybe<T>(s, this.shadowRoot!);
  }
  $qsa<T extends HTMLElement = HTMLElement>(s: string): NodeListOf<T> {
    return $qsa<T>(s, this.shadowRoot!);
  }

  addPointerEvent(config: GestureManager.PointerEvents): LonaWebComponent {
    GESTURE_MANAGER.addPointerEvent(this, config);
    return this;
  }

  assignAttributes(attributes: { [k: string]: string }): LonaWebComponent {
    return DomUtils.assignAttributes(this, attributes);
  }

  assignStyles(styles: CSSStyleWithVariables): LonaWebComponent {
    return DomUtils.assignStyles(this, styles);
  }
}

export namespace LonaWebComponent {
  export function asFactory<C extends LonaWebComponentClass>(
    t: C
  ): Factory<InstanceType<C>> {
    return t.make.bind(t) as Factory<InstanceType<C>>;
  }
}
